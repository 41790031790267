import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/Layout"

export default function company() {
  return (
    <Layout>
      <div className="min-w-screen min-h-screen flex flex-col">
        <div className="flex flex-col w-full h-full md:h-screen mx-auto md:flex-row md:items-center items-center justify-center mb-3 md:mb-0">
          <div className="w-full h-full md:h-screen py-5 md:w1/2 flex flex-col items-center justify-center bg-black md:bg-white md:bg-cta-img md:bg-no-repeat md:bg-contain md:bg-center">
            <h1 className="leading-normal mt-0 mb-2 text-white md:text-black text-center flex flex-col">
              <span className="mb-2 font-bold text-2xl md:text-5xl">
                Global. Simples. Conectado.
              </span>
              <span className="text-lg md:text-3xl">
                Soluções que facilitam o presente.
              </span>
            </h1>
          </div>
          <div className="w-full h-full md:h-screen md:w1/2 items-center justify-center px-6 md:px-10 py-3 flex flex-col text-gray-800 text-lg">
            <p className="mb-3">
              A <span className="font-bold">bewex</span> nasceu a partir da
              ideia de desenvolver softwares voltados para o mercado de comércio
              exterior, que pudesse integrar dados de empresas que tenham
              escritórios em qualquer parte do mundo de forma simples e segura.
            </p>
            <p className="mb-3">
              Nosso lema de criar{" "}
              <span className="font-bold">
                soluções que facilitam o presente
              </span>{" "}
              vem da ideia de desenvolver sistemas com uma abordagem mais
              moderna que os sistemas que já existem hoje, com foco especial na
              experiência do usuário, utilizando tecnologias de ponta, e
              garantindo assim uma longa durabilidade e fácil manutenção.
            </p>
            <p className="mb-3">
              Em pouco menos de um ano após sua fundação, em 2020, a{" "}
              <span className="font-bold">bewex</span> já se encontra presente
              em empresas com escritórios no Brasil, Uruguai, Chile, Perú,
              Colombia e Argentina.
            </p>
            <Link
              to="/contact"
              className="font-bold underline underline-offset-1 text-cyan-600 hover:text-blue-500"
            >
              Agende uma apresentação e conheça um pouco mais o nosso trabalho
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
